import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52e5cf5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_indicator = _resolveComponent("message-indicator")!
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    message: _ctx.message,
    class: "text-message"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["text-bubble", [_ctx.message.sender || 'bot']])
      }, [
        _createElementVNode("div", { innerHTML: _ctx.parseUrlInMessage }, null, 8, _hoisted_1),
        _createVNode(_component_message_indicator, { message: _ctx.message }, null, 8, ["message"])
      ], 2)
    ]),
    _: 1
  }, 8, ["message"]))
}