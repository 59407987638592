import { View } from "@/classes/inbox/View";
import { orderBy, query, Query, where } from "firebase/firestore";
import { store } from "@/store";
import { UserStore } from "@/store/constants";

export class ViewSnoozedOwn extends View {
  constructor() {
    super("Deine zurückgestellten Konversationen");
    this.snoozedVisible = true;
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultQuery,
      where("snoozed", ">", new Date()),
      where("operator", "==", store.getters[UserStore.Getters.UID]),
      orderBy("snoozed", "asc")
    );
  }
}
