import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { fetchCloudRun } from "@/api/Helper";

export const updateOperator = async (id: string, operatorId: string) => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/conversation/chatbots/" +
      chatbotId +
      "/conversations/" +
      id,
    "PUT",
    {},
    {
      operator: operatorId,
    }
  );
};
