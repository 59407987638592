import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d62d80e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_indicator = _resolveComponent("message-indicator")!
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    message: _ctx.message,
    class: "image-message"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["image", [_ctx.message.sender, !_ctx.message.content.text ? 'no-text' : '']])
      }, [
        _createElementVNode("video", {
          src: _ctx.message.content.url,
          controls: ""
        }, null, 8, _hoisted_1),
        (_ctx.message.content.text)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.message.content.text) + " ", 1),
              _createVNode(_component_message_indicator, { message: _ctx.message }, null, 8, ["message"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_message_indicator, { message: _ctx.message }, null, 8, ["message"])
            ]))
      ], 2)
    ]),
    _: 1
  }, 8, ["message"]))
}