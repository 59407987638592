
import { defineComponent, PropType } from "vue";
import { useStore } from "@/store";
import MdCard from "@/components/md/MdCard/MdCard.vue";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";
import MdBadge from "@/components/md/MdBadge/MdBadge.vue";
import { ITag } from "@/types/inbox/tags";
import { InboxStore } from "@/store/constants";
import MdButton from "@/components/md/MdButton/MdButton.vue";

export default defineComponent({
  name: "DetailsTagSearch",
  components: { MdButton, MdBadge, MdCheckbox, MdInputField, MdCard },
  props: {
    active: Array as PropType<string[]>,
  },
  emits: ["change", "close"],
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      query: "",
      activeTags: [] as string[],
    };
  },
  mounted() {
    this.activeTags = [...(this.active || [])];
    (this.$refs.input as any).$refs.input.select();
  },
  computed: {
    tags(): ITag[] {
      return this.store.getters[InboxStore.Getters.GET_TAGS] || [];
    },
    filteredTags(): ITag[] {
      return this.tags.filter((tag) => {
        return tag.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
  },
  methods: {
    getColor(colorName: string) {
      return this.store.getters[InboxStore.Getters.GET_TAG_COLOR](colorName)
        .color;
    },
    save() {
      this.$emit("change", this.activeTags);
    },
    close() {
      this.$emit("close");
    },
  },
});
