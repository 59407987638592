
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore, InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import MdTextarea from "@/components/md/MdInput/MdTextarea.vue";
import { createAnswer } from "@/api/inbox/settings/answers/create";
import { IWhatsAppTemplate } from "@/types/WhatsappTemplate";
import { IChannel } from "@chatcaptain/types/dist/chatbot/channels";
import { sendTemplate } from "@/api/inbox/conversations/send-template";
import { IChat } from "@chatcaptain/types/dist/chatbot";

export default defineComponent({
  name: "TemplateSelectModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
    MdInputField,
  },
  data() {
    return {
      query: "",
      targetTemplate: null as IWhatsAppTemplate | null,
      placeholders: [] as string[],
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  mounted() {
    this.placeholders = [];
    this.store.dispatch(BotStore.Actions.LOAD_CHANNELS);
  },
  computed: {
    channels(): IChannel[] | null {
      return this.store.getters[BotStore.Getters.GET_CHANNELS];
    },
    templates(): IWhatsAppTemplate[] | null {
      return this.store.getters[BotStore.Getters.GET_WHATSAPP_TEMPLATES];
    },
    filteredTemplates(): IWhatsAppTemplate[] {
      if (!this.templates) return [];
      return this.templates.filter((template) => {
        return template.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
    headerPlaceholders(): string[] {
      if (!this.targetTemplate) return [];
      return this.getPlaceholders(
        this.getContentFromTemplate(this.targetTemplate, "HEADER")
      );
    },
    bodyPlaceholders(): string[] {
      if (!this.targetTemplate) return [];
      return this.getPlaceholders(
        this.getContentFromTemplate(this.targetTemplate, "BODY")
      );
    },
    conversation(): IChat | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
    },
  },
  watch: {
    channels(channels: IChannel[]) {
      if (!channels) return;
      const whatsAppChannel = channels.find(
        (channel) => channel.type === "whatsapp"
      );
      if (!whatsAppChannel) return;
      this.store.commit(BotStore.Mutations.SET_TARGET_CHANNEL, whatsAppChannel);
    },
  },
  methods: {
    getPlaceholders(text: string): string[] {
      const matches = text.match(/{{[0-9]}}/g);
      return matches || [];
    },
    async sendTemplate() {
      if (!this.conversation || !this.conversation.id) return;
      if (!this.targetTemplate || !this.targetTemplate.id) return;
      this.setLoadingState(true);
      try {
        await sendTemplate(
          this.conversation.id,
          this.targetTemplate.id as string,
          [],
          this.placeholders
        );
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Template gesendet",
        });
        this.placeholders = [];
      } catch (err) {
        console.error("err: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Template konnte nicht gesendet werden",
        });
      }
      this.targetTemplate = null;
      this.setLoadingState(false);
      this.close();
    },
    getContentFromTemplate(
      template: IWhatsAppTemplate,
      contentPart: "HEADER" | "BODY" | "FOOTER"
    ): string {
      if (!template.translations || !template.translations["de"]) return "/";
      const part = template.translations["de"].find(
        (translation) => translation.type && translation.type === contentPart
      );
      if (part && part.text) return part.text;
      return "/";
    },
  },
  unmounted() {
    this.targetTemplate = null;
  },
});
