
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { IFileMessage } from "@chatcaptain/types/dist/chatbot";

export default defineComponent({
  name: "VideoMessage",
  components: { Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<IFileMessage>,
      required: true,
    },
  },
  methods: {
    openInNewTab() {
      window.open(this.message.content.url, "_blank");
    },
  },
});
