
import { defineComponent } from "vue";
import { useStore } from "@/store";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import { MdInputField } from "@/components/md/MdInput";

export default defineComponent({
  name: "DetailsLinkField",
  components: { MdButton, MdInputField },
  emits: ["change", "update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      link: this.modelValue,
    };
  },
  methods: {
    openLink() {
      if (!this.link) return;
      window.open(this.link.toString(), "_blank");
    },
    onChange() {
      this.$emit("update:modelValue", this.link);
      this.$emit("change");
    },
  },
});
