import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Searchs a conversation.
 * @param searchPhrase to look for in conversation
 */
export const searchConversations = async (
  searchPhrase: string
): Promise<any> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const response = await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/typesense/collection/" +
      chatbotId +
      "_conversations",
    "GET",
    { q: searchPhrase, query_by: "variables,display_name" }
  );
  return response.result.map((found) => found.id);
};
