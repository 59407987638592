
import { defineComponent, PropType } from "vue";
import { IChatMessage } from "@chatcaptain/types/chatbot";
import { DateFormat } from "@/classes/DateFormat";

export default defineComponent({
  name: "MessageIndicator",
  props: {
    message: {
      type: Object as PropType<IChatMessage>,
      required: true,
    },
  },
  computed: {
    time(): string {
      let date: Date;
      if (typeof this.message.created === "string") {
        date = new Date(this.message.created);
      } else if (this.message.created instanceof Date) {
        date = this.message.created;
      } else {
        date = this.message.created.toDate();
      }
      return DateFormat.formatTime(date);
    },
    // 1 for sent, 2 for received, 3 for read
    messageStateLevel(): number {
      if (this.message.read) {
        return 3;
      } else if (this.message.received) {
        return 2;
      } else if (this.message.sent) {
        return 1;
      } else {
        return 0;
      }
    },
  },
});
