import { View } from "@/classes/inbox/View";
import { orderBy, query, Query, where } from "firebase/firestore";
import { UserStore } from "@/store/constants";
import { store } from "@/store";

export class ViewMentioned extends View {
  constructor() {
    super("Erwähnt");
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultQuery,
      where(
        "mentioned_operators",
        "array-contains",
        store.getters[UserStore.Getters.UID]
      ),
      orderBy("updated", "desc")
    );
  }
}
