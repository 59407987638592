
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { IChatMessage } from "@chatcaptain/types/chatbot";
import { DateFormat } from "@/classes/DateFormat";
import MdAlert from "@/components/md/MdAlert/MdAlert.vue";

export default defineComponent({
  name: "MessageInfoModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdAlert,
    MdModal,
    MdButton,
  },
  props: {
    message: {
      type: Object as PropType<IChatMessage>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  methods: {
    formatDate(date: Date): string {
      return (
        DateFormat.formatDate(date) +
        " um " +
        DateFormat.formatTime(date) +
        " Uhr"
      );
    },
  },
});
