
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import {
  IButtonsMessageReply,
  ITextMessage,
} from "@chatcaptain/types/dist/chatbot";

export default defineComponent({
  name: "UserPayloadMessage",
  components: { Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<IButtonsMessageReply>,
      required: true,
    },
  },
});
