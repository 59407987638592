
import { defineComponent, PropType } from "vue";
import { useStore } from "@/store";
import { ICustomFieldInput } from "@/types/inbox/fields";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import { uploadAttachment } from "@/api/inbox/conversations/attachments/upload";
import { InboxStore, RootStore } from "@/store/constants";
import { updateField } from "@/api/inbox/conversations/update-field";
import { ISnackbarData } from "@/components/md/MdSnackbar";
import { IAttachment } from "@chatcaptain/types/dist/chatbot";
import { getAttachment } from "@/api/inbox/conversations/attachments/get";
import { deleteAttachment } from "@/api/inbox/conversations/attachments/delete";

export default defineComponent({
  name: "DetailsFileField",
  components: { MdProgressIndicatorCircular, MdButton },
  props: {
    field: {
      type: Object as PropType<ICustomFieldInput>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      uploading: false,
      attachment: null as IAttachment | null,
    };
  },
  watch: {
    field: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.field.value) {
          if (!this.conversationId) return;
          this.attachment = await getAttachment(
            this.conversationId,
            this.field.value as string
          );
        }
      },
    },
  },
  computed: {
    conversationId(): string | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION_ID];
    },
  },
  methods: {
    openInNew() {
      if (!this.attachment) return;
      window.open(this.attachment.url, "_blank");
    },
    async uploadFile(event: InputEvent) {
      try {
        if (!this.conversationId) return;
        if (!event.target) return;
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          const file = target.files[0];
          this.uploading = true;
          const attachemnt = await uploadAttachment(this.conversationId, file);
          this.attachment = attachemnt;
          if (!attachemnt.id) return;
          await updateField(
            this.conversationId,
            this.field.name,
            attachemnt.id
          );
          this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
            content: "Datei hochgeladen",
            state: "success",
          } as ISnackbarData);
        }
      } catch (err) {
        console.error("err: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          content: "Datei konnte nicht hochgeladen werden",
          state: "error",
        } as ISnackbarData);
      }
      this.uploading = false;
    },
    async deleteAttachment() {
      try {
        if (!this.conversationId) return;
        this.uploading = true;
        await deleteAttachment(this.conversationId, this.field.value as string);
        await updateField(this.conversationId, this.field.name, null);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          content: "Datei gelöscht",
          state: "success",
        } as ISnackbarData);
      } catch (err) {
        console.error("err: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          content: "Datei konnte nicht gelöscht werden",
          state: "error",
        } as ISnackbarData);
      }
      this.uploading = false;
    },
  },
});
