import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

export const setArchived = async (id: string, archived: boolean) => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await updateDoc(
    doc(getFirestore(), "dialogs/" + chatbotId + "/chats/" + id),
    {
      archived,
    }
  );
};
