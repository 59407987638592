
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { INoteMessage } from "@chatcaptain/types/chatbot";
import MessageIndicator from "@/components/inbox/messages/MessageIndicator.vue";
import { useStore } from "@/store";
import { AdminStore, UserStore } from "@/store/constants";

export default defineComponent({
  name: "NoteMessage",
  components: { Message, MessageIndicator },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<INoteMessage>,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const user = store.getters[UserStore.Getters.USER];
    return {
      store,
      user,
    };
  },
  computed: {
    displayMessage(): string {
      let message = this.parseUrlInMessage;
      if (!this.message.content.mentions) return message;
      this.message.content.mentions.forEach((mentionedId) => {
        const mentionedUser =
          this.store.getters[AdminStore.Getters.GET_USER](mentionedId);
        message = mentionedUser?.name
          ? message.replace(
              "@" + mentionedUser.name,
              "<span class='mention'>@" + mentionedUser.name + "</span>"
            )
          : message;
      });
      return message;
    },
    localUserMentioned(): boolean {
      return this.message.content.mentions.includes(this.user.uid);
    },
    sender(): string {
      const user = this.store.getters[AdminStore.Getters.GET_USER](
        this.message.operatorId as string
      );
      if (user) {
        return user.email;
      }
      return "Unbekannt";
    },
  },
});
