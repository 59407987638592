
import { defineComponent, PropType } from "vue";
import {
  IButtonsMessage,
  IChat,
  ITextMessage,
} from "@chatcaptain/types/dist/chatbot";
import { DateFormat } from "@/classes/DateFormat";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import WhatsAppLogo from "@/assets/images/channels/whatsapp.svg";

export default defineComponent({
  name: "Conversation",
  props: {
    conversation: Object as PropType<IChat>,
  },
  setup() {
    return {
      store: useStore(),
      WhatsAppLogo,
    };
  },
  data() {
    return {
      intervalId: null as NodeJS.Timer | null,
      dateTime: "",
    };
  },
  mounted() {
    this.dateTime = this.getDateTime();
    this.intervalId = setInterval(() => {
      this.dateTime = this.getDateTime();
    }, 1000 * 60);
  },
  unmouted() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  computed: {
    isOpen(): boolean {
      const currentConversation =
        this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
      if (!currentConversation || !this.conversation) return false;
      return currentConversation.id === this.conversation.id;
    },
    isUnread(): boolean {
      if (!this.conversation) return false;
      return this.conversation.unread;
    },
    snoozedTime(): string {
      if (!this.conversation) return "";
      if (!this.conversation.snoozed) return "";
      const date = this.conversation.snoozed.toDate();
      const now = new Date();
      if (
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        return `bis heute ${DateFormat.formatTime(date)}`;
      } else {
        return `bis ${DateFormat.formatDate(date)} um ${DateFormat.formatTime(
          date
        )}`;
      }
    },
    lastMessageText(): string {
      const lastMessage = this.conversation?.last_message;
      if (!lastMessage) {
        return "/";
      }
      switch (lastMessage.type) {
        case "text": {
          const msg = lastMessage as ITextMessage;
          return (
            (msg.sender === "bot" ? "Du: " : "") +
            (msg.content.message || msg.content.text)
          );
        }
        case "user_text": {
          const msg = lastMessage as ITextMessage;
          return msg.content.message;
        }
        //TODO add all types
        case "buttons": {
          const msg = lastMessage as IButtonsMessage;
          return msg.content.buttons?.map((button) => button.name).join(", ");
        }
      }
      return "/";
    },
  },
  methods: {
    getDateTime(): string {
      const date = this.conversation?.updated.toDate() || new Date();
      if (date.getTime() >= Date.now() - 60 * 1000) {
        return "jetzt";
      } else if (date.getTime() >= Date.now() - 60 * 60 * 1000) {
        return `vor ${Math.floor(
          (Date.now() - date.getTime()) / 1000 / 60
        )} Min`;
      } else if (date.getTime() >= Date.now() - 24 * 60 * 60 * 1000) {
        return `vor ${Math.floor(
          (Date.now() - date.getTime()) / 1000 / 60 / 60
        )} Std`;
      }
      return DateFormat.formatDate(date);
    },
    onClick() {
      if (!this.conversation) return;
      this.$router.push({
        path:
          "/inbox/" +
          this.$route.params.chatbotId +
          "/" +
          (this.$route.params.view || "all") +
          "/" +
          this.conversation.id,
      });
    },
  },
});
