import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fad14fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answers" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAnswers, (answer) => {
        return (_openBlock(), _createElementBlock("li", {
          key: answer.id,
          onClick: ($event: any) => (_ctx.onSelect(answer))
        }, [
          _createElementVNode("span", null, "/" + _toDisplayString(answer.name), 1),
          _createElementVNode("p", null, _toDisplayString(answer.message), 1)
        ], 8, _hoisted_2))
      }), 128))
    ])
  ]))
}