import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  query,
  Query,
  DocumentSnapshot,
  startAfter,
  Unsubscribe,
  where,
  CollectionReference,
  getDocs,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { IChat } from "@chatcaptain/types/chatbot";
import firebase from "firebase/compat";
import DocumentData = firebase.firestore.DocumentData;

export abstract class View {
  private readonly name: string;
  private moreChatsAvailable = true;
  private firstQuery = true;
  private lastDocument: DocumentSnapshot | null = null;
  private listeners: Unsubscribe[] = [];
  private documents: DocumentData[] = [];
  protected readonly defaultQuery: Query;
  protected readonly defaultCollection: CollectionReference;
  protected snoozedVisible = false;

  protected constructor(name: string) {
    this.name = name;
    this.defaultCollection = collection(
      getFirestore(),
      "dialogs/" + store.getters[InboxStore.Getters.GET_CHATBOT_ID] + "/chats"
    );
    this.defaultQuery = query(
      this.defaultCollection,
      where("archived", "==", false),
      limit(25)
    );
  }

  abstract getQuery(): Promise<Query>;

  async load(): Promise<void> {
    let currentQuery = await this.getQuery();
    if (this.lastDocument)
      currentQuery = query(currentQuery, startAfter(this.lastDocument));

    this.listeners.push(
      onSnapshot(currentQuery, (snapshot) => {
        for (const change of snapshot.docChanges()) {
          const document = change.doc;
          const data = document.data() as IChat;
          // if (
          //   !this.snoozedVisible &&
          //   data.snoozed &&
          //   data.snoozed.toDate() > new Date()
          // )
          //   continue;

          switch (change.type) {
            case "added":
              {
                this.documents.push(document);
              }
              break;
            case "modified":
              {
                const index = this.documents.findIndex(
                  (doc) => doc.id === document.id
                );
                this.documents[index] = document;
              }
              break;
            case "removed":
              {
                const index = this.documents.findIndex(
                  (doc) => doc.id === document.id
                );
                if (index > -1) this.documents.splice(index, 1);
              }
              break;
          }
        }
        if (!this.snoozedVisible) {
          this.documents.sort((a, b) => {
            return a.data()?.updated > b.data()?.updated ? -1 : 1;
          });
        }

        const conversations = this.documents.map((doc) => {
          const res = { id: doc.id, ...doc.data() } as IChat;
          res.messages = [];
          return res;
        });

        store.commit(InboxStore.Mutations.SET_CONVERSATIONS, conversations);

        this.firstQuery = false;
        if (snapshot.docs.length > 0) {
          this.lastDocument = snapshot.docs[snapshot.docs.length - 1];
        } else {
          this.moreChatsAvailable = false;
        }
      })
    );
  }

  unload() {
    this.lastDocument = null;
    this.listeners.forEach((listener) => listener());
    this.listeners = [];
    this.documents = [];
  }

  getName(): string {
    return this.name;
  }
}
