import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { IUserResult } from "@chatcaptain/types/organization";

export const createNote = async (
  id: string,
  note: string,
  mentions: IUserResult[]
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      id +
      "/notes",
    "POST",
    {},
    {
      text: note,
      mentions,
    }
  );
};
