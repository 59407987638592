import { View } from "@/classes/inbox/View";
import { orderBy, query, Query } from "firebase/firestore";

export class ViewAll extends View {
  constructor() {
    super("Alle Konversationen");
  }

  async getQuery(): Promise<Query> {
    return query(this.defaultQuery, orderBy("updated", "desc"));
  }
}
