import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-371fe6a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_badge = _resolveComponent("md-badge")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_details_tag_search = _resolveComponent("details-tag-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "tags",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectorVisible = true))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tagId) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: tagId }, [
          (_ctx.getTag(tagId))
            ? (_openBlock(), _createBlock(_component_md_badge, {
                key: 0,
                color: _ctx.getColor(_ctx.getTag(tagId).color)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTag(tagId).name), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createVNode(_component_md_button, {
        variant: "icon",
        mode: "icon_small",
        icon: "add"
      })
    ]),
    (_ctx.selectorVisible)
      ? (_openBlock(), _createBlock(_component_details_tag_search, {
          key: 0,
          ref: "selector",
          onChange: _ctx.onTagsUpdate,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (this.selectorVisible = false)),
          active: _ctx.tags
        }, null, 8, ["onChange", "active"]))
      : _createCommentVNode("", true)
  ]))
}