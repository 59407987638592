import { View } from "@/classes/inbox/View";
import { orderBy, query, Query, where } from "firebase/firestore";

export class ViewUnread extends View {
  constructor() {
    super("Ungelesen");
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultQuery,
      where("unread", "==", true),
      orderBy("updated", "desc")
    );
  }
}
