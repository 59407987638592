import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { fetchCloudRun } from "@/api/Helper";
import { IAttachment } from "@chatcaptain/types/dist/chatbot";

export const getAttachment = async (
  conversationId: string,
  attachmentId: string
): Promise<IAttachment> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const response = await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/conversation/chatbots/" +
      chatbotId +
      "/conversations/" +
      conversationId +
      "/attachments/" +
      attachmentId,
    "GET",
    {}
  );
  return response.attachment as IAttachment;
};
