import { View } from "@/classes/inbox/View";
import { getDocs, orderBy, query, Query, where } from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { ICustomView } from "@/types/inbox/views";
import chat from "@/components/inbox/Chat.vue";
import firebase from "firebase/compat";
import DocumentData = firebase.firestore.DocumentData;

export class ViewCustom extends View {
  private readonly customId: string;

  constructor(id: string) {
    super("Custom View");
    this.customId = id;
  }

  getCustomId(): string {
    return this.customId;
  }

  async getQuery(): Promise<Query> {
    const view: ICustomView = store.getters[InboxStore.Getters.GET_VIEW](
      this.customId
    );
    if (!view) return this.defaultQuery;
    let chatQuery = this.defaultQuery;
    if (view.tags && view.tags.length >= 1) {
      chatQuery = query(
        chatQuery,
        where("tags", "array-contains-any", view.tags),
        orderBy("updated", "desc")
      );
    }

    if (view.sort && view.sort !== "none") {
      chatQuery = query(chatQuery, orderBy(view.sort, "desc"));
    }
    view.fields?.forEach((field) => {
      switch (field) {
        case "unread":
          chatQuery = query(chatQuery, where(field, "==", true));
          break;
      }
    });
    return chatQuery;
  }
}
