import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_message_info_modal = _resolveComponent("message-info-modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", [_ctx.message.sender || 'bot']])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["message-wrapper", [_ctx.message.sender || 'bot']])
    }, [
      _createVNode(_component_md_button, {
        variant: "icon",
        grey: "",
        mode: "icon_small",
        icon: "info",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.infoModal.open()))
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createVNode(_component_message_info_modal, {
      ref: "infoModal",
      message: _ctx.message
    }, null, 8, ["message"])
  ], 2))
}