import { store } from "@/store";
import { InboxStore, UserStore } from "@/store/constants";
import { IAttachment } from "@chatcaptain/types/dist/chatbot";

export const uploadAttachment = async (
  conversationId: string,
  file: Blob
): Promise<IAttachment> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const idToken: string = store.getters[UserStore.Getters.ID_TOKEN];
  const formData = new FormData();
  formData.append("attachment", file);
  const response = await fetch(
    process.env.VUE_APP_BASE_URL +
      "/conversation/chatbots/" +
      chatbotId +
      "/conversations/" +
      conversationId +
      "/attachments?idToken=" +
      idToken,
    {
      method: "POST",
      body: formData,
    }
  );
  const json = await response.json();
  return json.attachment as IAttachment;
};
