
import { defineComponent } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import MdCheckbox from "@/components/md/MdCheckbox/MdCheckbox.vue";
import MdBadge from "@/components/md/MdBadge/MdBadge.vue";
import { useStore } from "@/store";
import { IChat } from "@chatcaptain/types/chatbot";
import { AdminStore, InboxStore } from "@/store/constants";
import WhatsAppLogo from "@/assets/images/channels/whatsapp.svg";
import DetailsTags from "@/components/inbox/DetailsTags.vue";
import MdProgressIndicatorLinear from "@/components/md/MdProgressIndicator/MdProgressIndicatorLinear.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import { updateDisplayName } from "@/api/inbox/conversations/update-display-name";
import { ICustomField, ICustomFieldInput } from "@/types/inbox/fields";
import { updateField } from "@/api/inbox/conversations/update-field";
import DetailsFileField from "@/components/inbox/DetailsFileField.vue";
import MdSelect from "@/components/md/MdInput/MdSelect.vue";
import { DateFormat } from "@/classes/DateFormat";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";
import { vOnClickOutside } from "@vueuse/components";
import DetailsLinkField from "@/components/inbox/DetailsLinkField.vue";
import { MdContentEditable } from "@/components/md/MdInput";
import { updateNotes } from "@/api/inbox/conversations/update-notes";
import MdAvatar from "@/components/md/MdAvatar/MdAvatar.vue";
import { IUser, IUserResult } from "@chatcaptain/types/dist/organization";
import MdMenu from "@/components/md/MdMenu/MdMenu.vue";
import { IMenuItem } from "@/components/md/MdMenu";
import { updateOperator } from "@/api/inbox/conversations/update-operator";

export default defineComponent({
  name: "Details",
  directives: {
    vOnClickOutside,
  },
  components: {
    MdMenu,
    MdAvatar,
    MdSelect,
    DetailsFileField,
    TransitionFade,
    MdProgressIndicatorLinear,
    DetailsTags,
    MdCheckbox,
    MdInputField,
    MdButton,
    MdProgressIndicatorCircular,
    DetailsLinkField,
    MdContentEditable,
  },
  data() {
    return {
      WhatsAppLogo,
      displayName: "",
      processing: false,
      activeSection: "",
      isLoading: false,
      saveTimer: null as NodeJS.Timer | null,
      openSection: false,
      focused: false,
      isEditing: false,
      notes: null as string | null,
      sectionChanged: false,
      chooseOperatorActive: false,
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  watch: {
    conversation: {
      immediate: true,
      handler(conversation: IChat | null, oldConversation: IChat | null) {
        if (!conversation && oldConversation) {
          if (this.sectionChanged) {
            if (this.saveTimer) clearTimeout(this.saveTimer);
            const sectionToSave = this.sections?.find(
              (section) => section.id === this.activeSection
            );
            if (oldConversation) {
              console.log("save fields");
              this.saveAllFields(oldConversation, sectionToSave);
            }
          }
          this.isLoading = true;
          this.isEditing = false;
        } else if (conversation?.id === oldConversation?.id) {
          this.isEditing = true;
        }

        if (!this.conversation) {
          this.displayName = "";
          return;
        }

        this.displayName = this.conversation.displayName;
        this.notes = this.conversation.notes || null;
        if (!this.isEditing) {
          console.log("load sections 2");
          this.loadSections();
        }
      },
    },
    sections() {
      console.log("load sections 1");
      this.loadSections();
    },
  },
  computed: {
    conversation(): IChat | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
    },
    sections(): ICustomField[] | null {
      return this.store.getters[InboxStore.Getters.GET_FIELDS];
    },
    mapUrl(): string | null {
      const location = this.store.getters[InboxStore.Getters.GET_LOCATION];

      if (!location) return null;
      return (
        "https://maps.googleapis.com/maps/api/staticmap?center=" +
        location.latitude +
        "," +
        location.longitude +
        "&size=400x600&zoom=6&key=AIzaSyDgYFbnXRtlMDdwmA2_OLUufnKCvPW77kU&markers=color:0x5c6bc0%7Csize:mid%7C" +
        location.latitude +
        "," +
        location.longitude
      );
    },
    operators(): IUserResult[] {
      return this.store.getters[AdminStore.Getters.GET_USERS];
    },
    currentOperator(): IUserResult {
      return this.operators.find(
        (operator) => operator.id === this.conversation?.operator
      ) as IUserResult;
    },
    operatorItems(): IMenuItem[] {
      return this.operators.map((operator) => {
        return {
          id: operator.id,
          value: operator.id,
          label: operator.name,
        };
      });
    },
  },
  methods: {
    onSelectOperator(operatorId: string) {
      updateOperator(this.conversation?.id as string, operatorId);
      this.chooseOperatorActive = false;
      setTimeout(() => {
        this.chooseOperatorActive = false;
        console.log("close");
      }, 10);
    },
    openInMaps() {
      const url = this.getGoogleMapsUrl();
      if (!url) return null;
      window.open(url, "_blank");
    },
    getGoogleMapsUrl(): string | null {
      const location = this.store.getters[InboxStore.Getters.GET_LOCATION];
      if (!location) return null;
      return (
        "https://www.google.com/maps/search/?api=1&query=" +
        location.latitude +
        "%2C" +
        location.longitude +
        "&zoom=9"
      );
    },
    loadSections() {
      if (!this.conversation) return;
      const variables = this.conversation.variables;
      if (variables && this.sections) {
        this.sections.forEach((section) => {
          section.fields.forEach((field) => {
            field.value = this.getValueIndex(variables, field.name) || "";
          });
        });
      } else if (this.sections) {
        this.sections.forEach((section) => {
          section.fields.forEach((field) => {
            field.value = "";
          });
        });
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 250);
    },
    getValueIndex(obj, is) {
      if (!obj) return;
      if (typeof is == "string") return this.getValueIndex(obj, is.split("."));
      else if (is.length == 0) return obj;
      else return this.getValueIndex(obj[is[0]], is.slice(1));
    },
    toggleSection(section: ICustomField) {
      if (!section.id) return;
      if (this.conversation) {
        const sectionToSave = this.sections?.find(
          (section) => section.id === this.activeSection
        );
        this.saveAllFields(this.conversation, sectionToSave);
      }
      this.activeSection = this.activeSection === section.id ? "" : section.id;
      this.openSection = true;
      setTimeout(() => (this.openSection = false), 100);
    },
    async updateDisplayName() {
      this.processing = true;
      try {
        if (!this.conversation || !this.conversation.id) return;
        await updateDisplayName(this.conversation.id, this.displayName);
      } catch (err) {
        console.error(err);
      }
      this.processing = false;
    },
    async fieldChangeHandler(conversation: IChat, field: ICustomFieldInput) {
      if (this.openSection) return;
      try {
        if (!conversation || !conversation.id) return;
        //detect if the field value has changed
        if (conversation.variables) {
          const value = this.getValueIndex(conversation.variables, field.name);
          if (value !== field.value) {
            await updateField(conversation.id, field.name, field.value || "");
          }
        } else {
          await updateField(conversation.id, field.name, field.value || "");
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onClickOutside() {
      if (this.focused) {
        this.focused = false;
        const sectionToSave = this.sections?.find(
          (section) => section.id === this.activeSection
        );
        if (!this.conversation) return;
        await this.saveAllFields(this.conversation, sectionToSave);
        this.isEditing = false;
      }
    },
    async saveAllFields(conversation: IChat, sectionToSave) {
      if (sectionToSave) {
        this.processing = true;
        const proms: Promise<any>[] = [];
        for (const field of sectionToSave.fields) {
          proms.push(this.fieldChangeHandler(conversation, field));
          // await this.fieldChangeHandler(field);
        }
        await Promise.all(proms);
        this.sectionChanged = false;
        this.processing = false;
      }
    },
    async onFieldChange() {
      if (this.saveTimer) clearTimeout(this.saveTimer);
      const sectionToSave = this.sections?.find(
        (section) => section.id === this.activeSection
      );
      if (!this.conversation) return;
      this.saveTimer = setTimeout(
        this.saveAllFields,
        1000,
        this.conversation,
        sectionToSave
      );
      this.sectionChanged = true;
    },
    async onNotesChange() {
      this.processing = true;
      if (!this.conversation || !this.conversation.id) return;
      await updateNotes(this.conversation.id, this.notes);
      this.processing = false;
    },
    onFieldFocus: function () {
      if (this.saveTimer) clearTimeout(this.saveTimer);
    },
  },
});
