
import { defineComponent, PropType } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import { assignToConversation } from "@/api/inbox/conversations/assign";
import MdMenu from "@/components/md/MdMenu/MdMenu.vue";
import { IMenuItem } from "@/components/md/MdMenu";
import TransitionMdMenu from "@/components/md/MdMenu/TransitionMdMenu.vue";
import MdDatepicker from "@/components/md/MdPicker/MdDatepicker.vue";
import { IMdDatepicker } from "@/components/md/MdPicker";
import { snoozeConversation } from "@/api/inbox/conversations/snooze";
import { useStore } from "@/store";
import { InboxStore, RootStore } from "@/store/constants";
import { ISnackbarData } from "@/components/md/MdSnackbar";

export default defineComponent({
  name: "SnoozeWrapper",
  components: { MdDatepicker, TransitionMdMenu, MdMenu },
  emits: ["close"],
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      customDate: new Date(),
      items: [
        {
          label: "für 3 Stunden",
          value: "3",
        },
        {
          label: "für 6 Stunden",
          value: "6",
        },
        {
          label: "bis morgen",
          value: "24",
        },
        {
          label: "für 2 Tage",
          value: "48",
        },
        {
          label: "für 3 Tage",
          value: "72",
        },
        {
          label: "für 7 Tage",
          value: "168",
        },
        {
          label: "Individuell",
          value: "custom",
        },
      ] as IMenuItem[],
    };
  },
  computed: {
    datepicker(): IMdDatepicker {
      return this.$refs.datepicker as IMdDatepicker;
    },
  },
  methods: {
    onSelect(value: string) {
      if (value === "custom") {
        this.datepicker.open();
      } else {
        this.setSnoozeDate(
          new Date(Date.now() + parseInt(value) * 60 * 60 * 1000)
        );
      }
    },
    onDatepickerSave() {
      this.setSnoozeDate(this.customDate);
    },
    async setSnoozeDate(date: Date) {
      this.$emit("close");
      const conversation =
        this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
      if (!conversation || !conversation.id) return;
      this.store.commit(InboxStore.Mutations.CLOSE_CURRENT_CONVERSATION);
      await snoozeConversation(conversation.id, date);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        content: "Konversation zurückgestellt",
        state: "success",
      } as ISnackbarData);
    },
  },
});
