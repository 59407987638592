import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78ef15d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "snooze-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_menu = _resolveComponent("md-menu")!
  const _component_transition_md_menu = _resolveComponent("transition-md-menu")!
  const _component_md_datepicker = _resolveComponent("md-datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_transition_md_menu, null, {
      default: _withCtx(() => [
        true
          ? (_openBlock(), _createBlock(_component_md_menu, {
              key: 0,
              items: _ctx.items,
              small: true,
              onSelect: _ctx.onSelect
            }, null, 8, ["items", "onSelect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_md_datepicker, {
      modelValue: _ctx.customDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customDate) = $event)),
      ref: "datepicker",
      onSave: _ctx.onDatepickerSave
    }, null, 8, ["modelValue", "onSave"])
  ]))
}