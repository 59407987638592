import { View } from "@/classes/inbox/View";
import { ViewAll } from "@/classes/inbox/ViewAll";
import { ViewMarked } from "@/classes/inbox/ViewMarked";
import { ViewUnassigned } from "@/classes/inbox/ViewUnassigned";
import { ViewMentioned } from "@/classes/inbox/ViewMentioned";
import { ViewAssigned } from "@/classes/inbox/ViewAssigned";
import { ViewOpen } from "@/classes/inbox/ViewOpen";
import { ViewSnoozed } from "@/classes/inbox/ViewSnoozed";
import { ViewArchived } from "@/classes/inbox/ViewArchived";
import { ViewNotSnoozed } from "@/classes/inbox/ViewNotSnoozed";
import { ViewUnread } from "@/classes/inbox/ViewUnread";
import { ViewSnoozedOwn } from "@/classes/inbox/ViewSnoozedOwn";
import { ViewOpenOwn } from "@/classes/inbox/ViewOpenOwn";

export class ViewManager {
  private static views: { [key: string]: View } = {};

  static register(name: string, view: View) {
    ViewManager.views[name] = view;
  }

  static getView(name: string): View | null {
    return ViewManager.views[name] || null;
  }
}

ViewManager.register("all", new ViewAll());
ViewManager.register("marked", new ViewMarked());
ViewManager.register("unassigned", new ViewUnassigned());
ViewManager.register("mentioned", new ViewMentioned());
ViewManager.register("assigned", new ViewAssigned());
ViewManager.register("open", new ViewOpen());
ViewManager.register("open_own", new ViewOpenOwn());
ViewManager.register("snoozed", new ViewSnoozed());
ViewManager.register("snoozed_own", new ViewSnoozedOwn());
ViewManager.register("not_snoozed", new ViewNotSnoozed());
ViewManager.register("archived", new ViewArchived());
ViewManager.register("unread", new ViewUnread());
