
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { ITextMessage } from "@chatcaptain/types/dist/chatbot";
import MessageIndicator from "@/components/inbox/messages/MessageIndicator.vue";

export default defineComponent({
  name: "TextMessage",
  components: { MessageIndicator, Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<ITextMessage>,
      required: true,
    },
  },
});
