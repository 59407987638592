
import { defineComponent, PropType } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import { assignToConversation } from "@/api/inbox/conversations/assign";

export default defineComponent({
  name: "ChatInputAssignOverlay",
  components: { TransitionFade, MdProgressIndicatorCircular, MdButton },
  props: {
    conversation: {
      type: Object as PropType<IChat>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async assign() {
      this.loading = true;
      await assignToConversation(this.conversation.id as string);
    },
  },
});
