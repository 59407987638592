
import { defineComponent, PropType, ref } from "vue";
import { useStore } from "@/store";
import MdBadge from "@/components/md/MdBadge/MdBadge.vue";
import { ITag } from "@/types/inbox/tags";
import { InboxStore } from "@/store/constants";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import DetailsTagSearch from "@/components/inbox/DetailsTagSearch.vue";
import { MdButton } from "@/components/md/MdButton";
import { updateTags } from "@/api/inbox/conversations/update-tags";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "DetailsTags",
  components: { MdBadge, MdButton, DetailsTagSearch },
  props: {
    conversation: {
      type: Object as PropType<IChat>,
      required: true,
    },
  },
  setup() {
    const selectorVisible = ref(false);
    const selector = ref(null);
    onClickOutside(selector, () => (selectorVisible.value = false));
    return {
      selectorVisible,
      selector,
      store: useStore(),
    };
  },
  data() {
    return {
      // selectorVisible: false,
    };
  },
  computed: {
    tags(): string[] {
      return this.conversation.tags || [];
    },
  },
  methods: {
    getColor(colorName: string) {
      return this.store.getters[InboxStore.Getters.GET_TAG_COLOR](colorName)
        .color;
    },
    onTagsUpdate(tags: string[]) {
      updateTags(this.conversation.id as string, tags);
      this.selectorVisible = false;
    },
    getTag(id: string): ITag | null {
      return this.store.getters[InboxStore.Getters.GET_TAG](id);
    },
  },
});
