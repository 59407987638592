import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  limitToLast,
  endBefore,
} from "firebase/firestore";
import { IChat, IChatMessage } from "@chatcaptain/types/chatbot";

export const getMoreMessages = async (
  conversationId: string,
  lastDoc: DocumentSnapshot | null
): Promise<{
  messages: IChatMessage[];
  lastMessage: DocumentSnapshot | null;
}> => {
  let allDocs: IChatMessage[] = [];
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];

  let q = query(
    collection(
      getFirestore(),
      "dialogs/" + chatbotId + "/chats/" + conversationId + "/messages"
    ),
    orderBy("created", "asc"),
    limitToLast(25)
  );

  if (lastDoc) q = query(q, endBefore(lastDoc));

  const docs = await getDocs(q);
  if (docs.size == 0) {
    const chatDoc = await getDoc(
      doc(getFirestore(), "dialogs/" + chatbotId + "/chats/" + conversationId)
    );
    if (chatDoc.exists()) {
      const data = chatDoc.data() as IChat;
      if (data.messages && data.messages.length > 0) allDocs = data.messages;
    }
  }

  const sourceIds: string[] = [];
  // fill allDocs array and sourceIds array
  docs.forEach((doc) => {
    allDocs.push({
      id: doc.id,
      ...doc.data(),
    } as IChatMessage);
    if (doc.data().source_id) {
      sourceIds.push(doc.data().source_id);
    }
  });

  // get duplicates in sourceIds
  const duplicateIds = sourceIds.filter(
    (item, index) => sourceIds.indexOf(item) != index
  );

  // remove duplicate messages in alldocs
  duplicateIds.forEach((id) => {
    const findIndex = allDocs.findIndex((doc) => doc.source_id === id);
    allDocs.splice(findIndex, 1);
  });

  // return docs.docs.map((doc) => {
  //   return {
  //     id: doc.id,
  //     ...doc.data(),
  //   } as IChatMessage;
  // });

  return {
    messages: allDocs,
    lastMessage: docs.size < 25 ? null : docs.docs[0],
  };
};
