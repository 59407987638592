
import { defineComponent } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import { useStore } from "@/store";
import { Timestamp } from "firebase/firestore";
import { DateFormat } from "@/classes/DateFormat";

export default defineComponent({
  name: "DaySeparator",
  mixins: [MessageMixin],
  setup() {
    return {
      store: useStore(),
    };
  },
  methods: {
    toDate(value: string | Date | Timestamp): Date {
      if (typeof value === "string") {
        return new Date(value);
      } else if (value instanceof Date) {
        return value;
      } else {
        return value.toDate();
      }
    },
    isSameDay(
      a: Date | Timestamp | string,
      b: Date | Timestamp | string
    ): boolean {
      a = this.toDate(a);
      b = this.toDate(b);
      return (
        a.getDate() === b.getDate() &&
        a.getMonth() === b.getMonth() &&
        a.getFullYear() === b.getFullYear()
      );
    },
  },
  computed: {
    day(): string {
      if (
        this.isSameDay(
          this.message.created as Timestamp | string | Date,
          new Date()
        )
      ) {
        return "Heute";
      } else if (
        this.isSameDay(
          this.message.created as Timestamp | string | Date,
          new Date(Date.now() - 1000 * 60 * 60 * 24)
        )
      ) {
        return "Gestern";
      } else {
        return DateFormat.formatDate(
          this.toDate(this.message.created as Timestamp | string | Date)
        );
      }
    },
  },
});
