
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore, InboxStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import { ITagColor } from "@/types/inbox/tags";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import { createTag } from "@/api/inbox/settings/tags/create";
import MdTextarea from "@/components/md/MdInput/MdTextarea.vue";
import { createAnswer } from "@/api/inbox/settings/answers/create";
import { IWhatsAppTemplate } from "@/types/WhatsappTemplate";
import { IChannel } from "@chatcaptain/types/dist/chatbot/channels";
import { sendTemplate } from "@/api/inbox/conversations/send-template";
import { IChat, IContactMessage } from "@chatcaptain/types/dist/chatbot";

export default defineComponent({
  name: "ContactMessageModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
  },
  props: {
    contact: {
      type: Object as PropType<IContactMessage["content"]>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
});
