
import { defineComponent } from "vue";
import Conversations from "@/components/inbox/Conversations.vue";
import Chat from "@/components/inbox/Chat.vue";
import Details from "@/components/inbox/Details.vue";
import { ViewManager } from "@/classes/inbox/ViewManager";
import { View } from "@/classes/inbox/View";
import { ViewAll } from "@/classes/inbox/ViewAll";
import { ViewCustom } from "@/classes/inbox/ViewCustom";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";

export default defineComponent({
  name: "InboxMain",
  components: { Chat, Conversations, Details },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      view: new ViewAll(),
    };
  },
  computed: {
    viewName(): string {
      return (this.$route.params.view as string) || "all";
    },
  },
  watch: {
    viewName: {
      immediate: true,
      handler() {
        const view = ViewManager.getView(this.viewName);
        if (view) {
          this.view = view;
        } else {
          const customView = new ViewCustom(this.viewName);
          this.view = customView;
        }
      },
    },
    view: {
      immediate: true,
      handler(view: View, oldView?: View) {
        if (oldView) oldView.unload();
        view.load();
      },
    },
  },
  methods: {
    loadMore() {
      this.store.commit(InboxStore.Mutations.SET_CONVERSATIONS_LOADING, true);
      this.view.load();
    },
  },
});
