import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a81fcf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    message: _ctx.message,
    class: "text-message"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, k) => {
          return (_openBlock(), _createElementBlock("span", { key: k }, _toDisplayString(button.name), 1))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["message"]))
}