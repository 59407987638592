import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Sends an attachment message to a conversation.
 * @param id of the conversation
 * @param attachmentId id of the attachment
 */
export const sendAttachmentMessage = async (
  id: string,
  attachmentId: string
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      id +
      "/send",
    "POST",
    {},
    {
      attachment: attachmentId,
    }
  );
};
