import { View } from "@/classes/inbox/View";
import { orderBy, query, Query, where } from "firebase/firestore";

export class ViewMarked extends View {
  constructor() {
    super("Markiert");
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultQuery,
      where("marked", "==", true),
      orderBy("updated", "desc")
    );
  }
}
