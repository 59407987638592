
import { defineComponent, PropType } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import { assignToConversation } from "@/api/inbox/conversations/assign";
import TemplateSelectModal from "@/components/inbox/TemplateSelectModal.vue";
import { IMdModal } from "@/components/md/MdDialog";

export default defineComponent({
  name: "ChatInputTemplateOverlay",
  components: {
    TransitionFade,
    MdProgressIndicatorCircular,
    MdButton,
  },
  emits: ["open"],
  props: {
    conversation: {
      type: Object as PropType<IChat>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
});
