
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { IContactMessage, ITextMessage } from "@chatcaptain/types/dist/chatbot";
import ContactMessageModal from "@/components/inbox/messages/ContactMessageModal.vue";
import { IMdModal } from "@/components/md/MdDialog";

export default defineComponent({
  name: "ContactMessage",
  components: { ContactMessageModal, Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<IContactMessage>,
      required: true,
    },
  },
  computed: {
    detailsModal(): IMdModal {
      return this.$refs.detailsModal as IMdModal;
    },
  },
});
