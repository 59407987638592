
import { defineComponent } from "vue";
import Conversation from "@/components/inbox/Conversation.vue";
import { IChat } from "@chatcaptain/types/chatbot";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import ConversationLoader from "@/components/inbox/ConversationLoader.vue";
import { MdInputField } from "@/components/md/MdInput";
import { searchConversations } from "@/api/inbox/conversations/search";
import {
  MdProgressIndicatorCircular,
  MdProgressIndicatorLinear,
} from "@/components/md/MdProgressIndicator";
import { getConversations } from "@/api/inbox/conversations/load";

export default defineComponent({
  name: "Conversations",
  components: {
    ConversationLoader,
    MdButton,
    Conversation,
    MdInputField,
    MdProgressIndicatorLinear,
    MdProgressIndicatorCircular,
  },
  props: {
    title: String,
    section: String,
  },
  emits: ["more"],
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      searchInConversations: false,
      searchPhrase: "",
      searching: false,
      first: true,
      showSearchResults: false,
      filteredChats: [] as IChat[],
    };
  },
  computed: {
    conversationsLoading(): boolean {
      return this.store.getters[InboxStore.Getters.GET_CONVERSATIONS_LOADING];
    },
    conversations(): IChat[] {
      return this.store.getters[InboxStore.Getters.GET_CONVERSATIONS];
    },
    filteredConversations(): IChat[] {
      return this.filteredChats;
    },
  },
  methods: {
    onListScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.conversationsLoading) return;
      if (scrollTop + clientHeight >= scrollHeight) this.$emit("more");
    },
    async searchConversations() {
      this.searching = true;
      (this.$refs.searchInput as any).$refs.input.focus();
      const ids: string[] = await searchConversations(this.searchPhrase);
      this.filteredChats = await getConversations(ids);
      this.showSearchResults = true;
      this.searching = false;
    },
    toggleSearch() {
      this.searchInConversations = !this.searchInConversations;
      if (!this.searchInConversations) {
        this.showSearchResults = false;
      } else {
        this.$nextTick(() => {
          (this.$refs.searchInput as any).$refs.input.focus();
          (this.$refs.searchInput as any).$refs.input.select();
        });
      }
    },
  },
});
