
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { IImageMessage } from "@chatcaptain/types/dist/chatbot";
import MessageIndicator from "@/components/inbox/messages/MessageIndicator.vue";

export default defineComponent({
  name: "ImageMessage",
  components: { MessageIndicator, Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<IImageMessage>,
      required: true,
    },
  },
  methods: {
    openInNewTab() {
      window.open(this.message.content.url, "_blank");
    },
  },
});
