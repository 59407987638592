import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Sends a text message to a conversation.
 * @param id of the conversation
 * @param text of the message
 */
export const sendTextMessage = async (
  id: string,
  text: string
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      id +
      "/send",
    "POST",
    {},
    {
      text,
    }
  );
};
