import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2628ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inbox content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_conversations = _resolveComponent("conversations")!
  const _component_chat = _resolveComponent("chat")!
  const _component_Details = _resolveComponent("Details")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_conversations, {
      class: "conversations",
      title: _ctx.view.getName(),
      onMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadMore()))
    }, null, 8, ["title"]),
    _createVNode(_component_chat, { class: "chat" }),
    _createVNode(_component_Details, { class: "details" })
  ]))
}