
import { defineComponent, ref } from "vue";
import TextMessage from "@/components/inbox/messages/TextMessage.vue";
import ImageMessage from "@/components/inbox/messages/ImageMessage.vue";
import ChatInputField from "@/components/inbox/ChatInput.vue";
import ButtonsMessage from "@/components/inbox/messages/ButtonsMessage.vue";
import { useStore } from "@/store";
import { InboxStore, RootStore, UserStore } from "@/store/constants";
import { IChat, IChatMessage } from "@chatcaptain/types/dist/chatbot";
import UserPayloadMessage from "@/components/inbox/messages/UserPayloadMessage.vue";
import NoteMessage from "@/components/inbox/messages/NoteMessage.vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import SnoozeWrapper from "@/components/inbox/SnoozeWrapper.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import EmptyHint from "@/components/cc/EmptyHint/EmptyHint.vue";
import TemplateMessage from "@/components/inbox/messages/TemplateMessage.vue";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import ContactMessage from "@/components/inbox/messages/ContactMessage.vue";
import VideoMessage from "@/components/inbox/messages/VideoMessage.vue";
import FileMessage from "@/components/inbox/messages/FileMessage.vue";
import AudioMessage from "@/components/inbox/messages/AudioMessage.vue";
import DaySeparator from "@/components/inbox/messages/DaySeparator.vue";
import { Timestamp } from "firebase/firestore";
import MdTooltip from "@/components/md/MdTooltip/MdTooltip.vue";
import { setArchived } from "@/api/inbox/conversations/set-archived";
import { ISnackbarData } from "@/components/md/MdSnackbar";
import { setUnread } from "@/api/inbox/conversations/set-unread";
import { getMoreMessages } from "@/api/inbox/conversations/load-more-messages";
import { onClickOutside } from "@vueuse/core";
import { DocumentSnapshot } from "firebase/firestore";
import { vInfiniteScroll } from "@vueuse/components";
import MdBadge from "@/components/md/MdBadge/MdBadge.vue";
import { DateFormat } from "@/classes/DateFormat";

export default defineComponent({
  name: "Chat",
  directives: {
    infiniteScroll: vInfiniteScroll,
  },
  components: {
    MdBadge,
    MdTooltip,
    DaySeparator,
    FileMessage,
    VideoMessage,
    AudioMessage,
    ContactMessage,
    ImageMessage,
    MdProgressIndicatorCircular,
    TemplateMessage,
    EmptyHint,
    TransitionFade,
    SnoozeWrapper,
    MdButton,
    NoteMessage,
    UserPayloadMessage,
    ButtonsMessage,
    ChatInputField,
    TextMessage,
  },
  setup() {
    const snoozeWrapper = ref(null);
    const snoozeActive = ref(false);
    onClickOutside(snoozeWrapper, () => (snoozeActive.value = false));
    return {
      snoozeActive,
      snoozeWrapper,
      store: useStore(),
    };
  },
  data() {
    return {
      loadingMessages: false,
      moreMessages: [] as IChatMessage[],
      lastMessage: null as DocumentSnapshot | null,
      canLoadMoreMessages: true,
      isLoading: false,
    };
  },
  computed: {
    conversation(): IChat | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
    },
    // isLoading(): boolean {
    //   return this.store.getters[
    //     InboxStore.Getters.GET_CURRENT_CONVERSATION_LOADING
    //   ];
    // },
    messages(): IChatMessage[] {
      // if (this.moreMessages && this.conversation?.messages) {
      //   return [...this.moreMessages, ...this.conversation.messages];
      // }
      // return this.conversation?.messages || [];
      return this.moreMessages || [];
    },
    snoozedTime(): string {
      if (!this.conversation) return "";
      if (!this.conversation.snoozed) return "";
      const date = this.conversation.snoozed.toDate();
      const now = new Date();
      if (
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        return `bis heute ${DateFormat.formatTime(date)}`;
      } else {
        return `bis ${DateFormat.formatDate(date)} um ${DateFormat.formatTime(
          date
        )}`;
      }
    },
  },
  watch: {
    conversation: {
      deep: true,
      async handler(conversation: IChat | null, oldConversation: IChat | null) {
        if (
          conversation &&
          conversation?.updated &&
          conversation.id === oldConversation?.id &&
          conversation.last_message &&
          conversation.last_message?.id !==
            this.messages[this.messages.length - 1].id
        ) {
          this.moreMessages.push(conversation.last_message);
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
        if (
          !conversation ||
          !oldConversation ||
          conversation.id !== oldConversation.id // || conversation.messages !== oldConversation.messages
        ) {
          // if (conversation?.id !== oldConversation?.id) this.isLoading = true;
          // reset on conversation change
          this.moreMessages = [];
          this.lastMessage = null;
          this.canLoadMoreMessages = true;
          this.isLoading = true;
          await this.loadMoreMessages(true);
          if (
            this.conversation?.id &&
            this.conversation.id ===
              this.store.getters[
                InboxStore.Getters.GET_CURRENT_CONVERSATION_ID
              ] &&
            this.conversation?.operator &&
            this.conversation?.unread === true
          ) {
            if (
              this.conversation.operator ===
              this.store.getters[UserStore.Getters.UID]
            ) {
              setUnread(this.conversation.id, false);
            }
          }
        } else {
          this.updateMessages();
        }
      },
    },
  },
  methods: {
    scrollToBottom() {
      const endOfList = document.getElementById("end-of-list");
      if (endOfList)
        endOfList.scrollIntoView({ behavior: "auto", block: "nearest" });
    },
    toDate(value: string | Date | Timestamp): Date {
      if (typeof value === "string") {
        return new Date(value);
      } else if (value instanceof Date) {
        return value;
      } else {
        try {
          return value.toDate();
        } catch (err) {
          console.log(err);
          debugger;
          return new Date();
        }
      }
    },
    isSameDay(
      a: Date | Timestamp | string,
      b: Date | Timestamp | string
    ): boolean {
      a = this.toDate(a);
      b = this.toDate(b);
      return (
        a.getDate() === b.getDate() &&
        a.getMonth() === b.getMonth() &&
        a.getFullYear() === b.getFullYear()
      );
    },
    async archive(archive: boolean) {
      if (!this.conversation?.id) return;
      setArchived(this.conversation.id, archive);
      this.store.commit(InboxStore.Mutations.CLOSE_CURRENT_CONVERSATION);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        content: archive ? "Archiviert" : "Nicht mehr archiviert",
        state: "success",
      } as ISnackbarData);
    },
    async setUnread() {
      if (!this.conversation?.id) return;
      setUnread(this.conversation.id, true);
      this.store.commit(InboxStore.Mutations.CLOSE_CURRENT_CONVERSATION);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        content: "Als ungelesen markiert",
        state: "success",
      } as ISnackbarData);
    },
    async loadMoreMessages(scrollToBottom?: boolean) {
      if (!this.canLoadMoreMessages) return;
      if (!this.isLoading) this.loadingMessages = true;
      if (!this.conversation || !this.conversation.id) return;

      const { messages, lastMessage } = await getMoreMessages(
        this.conversation.id,
        this.lastMessage
      );
      this.moreMessages.unshift(...messages);
      if (lastMessage) {
        this.lastMessage = lastMessage;
      } else {
        this.canLoadMoreMessages = false;
      }

      setTimeout(() => {
        this.isLoading = false;
        this.loadingMessages = false;
        this.$nextTick(() => {
          if (typeof scrollToBottom === "boolean" && scrollToBottom) {
            this.scrollToBottom();
          }
        });
      }, 200);
    },
    async updateMessages() {
      if (!this.conversation || !this.conversation.id) return;
      const { messages, lastMessage } = await getMoreMessages(
        this.conversation.id,
        null
      );
      for (const index in messages) {
        const msg = messages[index];
        //update message in moreMesssages
        const indexInMoreMessages = this.moreMessages.findIndex(
          (m) => m.id === msg.id
        );
        if (indexInMoreMessages > -1) {
          this.moreMessages.splice(indexInMoreMessages, 1, msg);
        }
        //const targetIndex = this.moreMessages.findIndex((m) => m.id === msg.id);
      }
    },
  },
});
