
import { defineComponent, PropType } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import Message from "@/components/inbox/messages/Message.vue";
import { IButtonsMessage, ITextMessage } from "@chatcaptain/types/dist/chatbot";

export default defineComponent({
  name: "ButtonsMessage",
  components: { Message },
  mixins: [MessageMixin],
  props: {
    message: {
      type: Object as PropType<IButtonsMessage>,
      required: true,
    },
  },
  computed: {
    buttons(): IButtonsMessage["content"]["buttons"] {
      return this.message.content.buttons || [];
    },
  },
});
