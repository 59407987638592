import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-391028e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    message: _ctx.message,
    class: "video-message"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("audio", {
          src: _ctx.message.content.url,
          controls: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["message"]))
}