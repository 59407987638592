import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d7bb0ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_indicator = _resolveComponent("message-indicator")!
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createBlock(_component_message, {
    message: _ctx.message,
    class: "text-message"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["text-bubble", [_ctx.message.sender]])
      }, [
        (_ctx.message.content.header)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.message.content.header), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.message.content.body) + " ", 1),
        (_ctx.message.content.footer)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message.content.footer), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_message_indicator, { message: _ctx.message }, null, 8, ["message"])
      ], 2)
    ]),
    _: 1
  }, 8, ["message"]))
}