import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12386f83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_badge = _resolveComponent("md-badge")!
  const _component_md_checkbox = _resolveComponent("md-checkbox")!
  const _component_md_card = _resolveComponent("md-card")!

  return (_openBlock(), _createBlock(_component_md_card, {
    variant: "elevated",
    class: "tag-search",
    onKeydown: _withKeys(_ctx.close, ["esc"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_md_button, {
          class: "close-button",
          variant: "icon",
          icon: "close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
        }),
        _createVNode(_component_md_input_field, {
          modelValue: _ctx.query,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
          variant: "outlined",
          label: "Tags filtern",
          size: "small",
          ref: "input"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_md_button, {
          variant: "filled",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Speichern")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTags, (tag) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tag.id
          }, [
            _createVNode(_component_md_checkbox, {
              modelValue: _ctx.activeTags,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeTags) = $event)),
              value: tag.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_md_badge, {
                  size: "small",
                  color: _ctx.getColor(tag.color)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tag.name), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1032, ["modelValue", "value"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["onKeydown"]))
}