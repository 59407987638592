
import { defineComponent } from "vue";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import { IAttachment, IChat } from "@chatcaptain/types/dist/chatbot";
import { InboxStore, RootStore, UserStore } from "@/store/constants";
import { useStore } from "@/store";
import ChatInputAssignOverlay from "@/components/inbox/ChatInputAssignOverlay.vue";
import TransitionFade from "@/components/cc/Transition/TransitionFade.vue";
import ChatInputAnswers from "@/components/inbox/ChatInputAnswers.vue";
import { createNote } from "@/api/inbox/conversations/create-note";
import MdProgressIndicatorCircular from "@/components/md/MdProgressIndicator/MdProgressIndicatorCircular.vue";
import { sendTextMessage } from "@/api/inbox/conversations/send-text-message";
import { MdContentEditable } from "@/components/md/MdInput";
import ChatInputTemplateOverlay from "@/components/inbox/ChatInputTemplateOverlay.vue";
import TemplateSelectModal from "@/components/inbox/TemplateSelectModal.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { uploadAttachment } from "@/api/inbox/conversations/attachments/upload";
import { ISnackbarData } from "@/components/md/MdSnackbar";
import { sendAttachmentMessage } from "@/api/inbox/conversations/send-attachment-message";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Editor, EditorContent } from "@tiptap/vue-3";
import ChatInputMentionUserSuggestion from "@/components/inbox/ChatInputMentionUserSuggestion";

export default defineComponent({
  name: "ChatInput",
  components: {
    TemplateSelectModal,
    ChatInputTemplateOverlay,
    MdProgressIndicatorCircular,
    ChatInputAnswers,
    TransitionFade,
    ChatInputAssignOverlay,
    MdButton,
    MdContentEditable,
    EditorContent,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      mode: "reply",
      message: "",
      loading: false,
      dropzoneVisible: false,
      editor: null as Editor | null,
    };
  },
  beforeUnmount() {
    this.editor?.destroy();
  },
  mounted() {
    this.editor = new Editor({
      editorProps: {
        handleKeyDown: (view, event) => {
          if (event.key == "Enter" && event.ctrlKey) this.send();
        },
      },
      extensions: [
        Text,
        Document,
        Paragraph,
        Placeholder.configure({
          placeholder: "Schreibe eine Notiz...",
        }),
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              Enter: () => this.editor.commands.setHardBreak(),
            };
          },
        }),
        Mention.configure({
          HTMLAttributes: {
            class: "tag",
          },
          suggestion: ChatInputMentionUserSuggestion,
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${
              node.attrs.label ?? node.attrs.id.name
            }`;
          },
        }),
      ],
      content: this.message,
      onUpdate: ({ editor }) => {
        this.message = editor.getText().trim() || "";
      },
    });
  },
  computed: {
    templateSelectModal(): IMdModal {
      return this.$refs.templateSelectModal as IMdModal;
    },
    conversation(): IChat | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
    },
    isAssigned(): boolean {
      return (
        this.store.getters[UserStore.Getters.UID] ===
        this.conversation?.operator
      );
    },
    isTemplateRequired(): boolean {
      if (!this.conversation || !this.conversation.last_user_action)
        return true;
      return (
        !this.conversation?.last_user_action ||
        this.conversation?.last_user_action.toDate() <
          new Date(Date.now() - 1000 * 60 * 60 * 24)
      );
    },
    isAnswerSearchActive(): boolean {
      return this.message.startsWith("/");
    },
    mentions() {
      const mentions: any = [];
      const json = this.editor?.getJSON() || {};
      json.content?.forEach((p) => {
        p.content?.forEach((x) => {
          if (x.type === "mention") {
            mentions.push(x.attrs?.id);
          }
        });
      });
      return mentions;
    },
  },
  methods: {
    onInput(event: InputEvent) {
      this.message = (event.target as HTMLInputElement).innerText;
    },
    async onFilesDrop(event: DragEvent) {
      event.preventDefault();
      this.dropzoneVisible = false;
      if (!event || !event.dataTransfer) return;
      const files = event.dataTransfer.files;
      if (files) await this.onAttachmentUpload([...files]);
    },
    async onAttachmentSelect(event: InputEvent) {
      const files = (event.target as HTMLInputElement).files;
      if (files) await this.onAttachmentUpload([...files]);
    },
    async onAttachmentUpload(files: File[]) {
      const attachments: IAttachment[] = [];
      this.loading = true;
      try {
        if (files && files.length > 0) {
          for (const file of files) {
            const attachment = await this.uploadFileAsAttachment(file);
            if (attachment) attachments.push(attachment);
          }
        }
        this.loading = false;
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          content: "Dateien werden verarbeitet und gleich gesendet...",
          state: "info",
        } as ISnackbarData);
        await this.sleep(4000);
        const proms: Promise<void>[] = [];
        if (attachments && attachments.length > 0) {
          attachments.forEach((attachment) => {
            proms.push(this.sendAttachment(attachment));
          });
        }
        await Promise.all(proms);
      } catch (err) {
        console.error("err: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Datei konnte nicht gesendet werden",
        } as ISnackbarData);
      }
      this.loading = false;
    },
    async uploadFileAsAttachment(file: File): Promise<IAttachment | null> {
      if (!this.conversation || !this.conversation.id) return null;
      return await uploadAttachment(this.conversation.id, file);
    },
    async sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async sendAttachment(attachment: IAttachment): Promise<void> {
      if (!this.conversation || !this.conversation.id || !attachment.id) return;
      await sendAttachmentMessage(this.conversation.id, attachment.id);
    },
    async send() {
      this.loading = true;
      if (this.mode === "note") {
        await createNote(
          this.conversation?.id as string,
          this.message,
          this.mentions
        );
      } else {
        await sendTextMessage(this.conversation?.id as string, this.message);
      }
      this.message = "";
      this.editor?.commands.clearContent();
      this.loading = false;
    },
  },
});
