import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac349462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name-time" }
const _hoisted_2 = {
  key: 0,
  class: "subject"
}
const _hoisted_3 = {
  key: 1,
  class: "snoozed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["conversation", { unread: _ctx.isUnread, active: _ctx.isOpen }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
  }, [
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle({
        backgroundImage: `url(${_ctx.conversation.photoURL || _ctx.WhatsAppLogo})`,
      })
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.conversation.displayName), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.dateTime), 1)
    ]),
    false
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "Betreffzeile falls zutreffend"))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, _toDisplayString(_ctx.lastMessageText), 1),
    (
        _ctx.conversation.snoozed &&
        _ctx.conversation.snoozed.toDate() > new Date() &&
        false
      )
      ? (_openBlock(), _createElementBlock("strong", _hoisted_3, _toDisplayString(_ctx.snoozedTime), 1))
      : _createCommentVNode("", true)
  ], 2))
}