import { View } from "@/classes/inbox/View";
import { limit, orderBy, query, Query, where } from "firebase/firestore";

export class ViewArchived extends View {
  constructor() {
    super("Archiviert");
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultCollection,
      where("archived", "==", true),
      orderBy("updated", "desc"),
      limit(25)
    );
  }
}
