import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { fetchCloudRun } from "@/api/Helper";

export const updateTags = async (id: string, tags: string[]) => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/conversation/chatbots/" +
      chatbotId +
      "/conversations/" +
      id,
    "PUT",
    {},
    {
      tags,
    }
  );
};
