import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62f59710"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "templates" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "template" }
const _hoisted_4 = { class: "placeholders" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Template senden",
    icon: "bolt",
    size: "xlarge"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Suche hier nach einem Template, das du senden möchtest. Anschließend kannst du die Platzhalter durch individuelle Texte ersetzen. ")
    ]),
    body: _withCtx(() => [
      (!_ctx.targetTemplate)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_md_input_field, {
              variant: "outlined",
              modelValue: _ctx.query,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
              label: "Templates filtern",
              ref: "nameInput"
            }, null, 8, ["modelValue"]),
            _createElementVNode("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTemplates, (template) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: template.id,
                  onClick: ($event: any) => (_ctx.targetTemplate = template)
                }, [
                  _createElementVNode("h4", null, _toDisplayString(template.name), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.getContentFromTemplate(template, "BODY")), 1)
                ], 8, _hoisted_2))
              }), 128))
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_md_button, {
              variant: "text",
              icon: "arrow_backward",
              grey: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.targetTemplate = null))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Zurück")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.targetTemplate.name), 1),
              _createElementVNode("h5", null, _toDisplayString(_ctx.getContentFromTemplate(_ctx.targetTemplate, "HEADER")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.getContentFromTemplate(_ctx.targetTemplate, "BODY")), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getContentFromTemplate(_ctx.targetTemplate, "FOOTER")), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
              ..._ctx.headerPlaceholders,
              ..._ctx.bodyPlaceholders,
            ], (placeholder, k) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "placeholder",
                  key: k
                }, [
                  _createVNode(_component_md_input_field, {
                    variant: "outlined",
                    modelValue: _ctx.placeholders[k],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.placeholders[k]) = $event),
                    label: placeholder,
                    ref_for: true,
                    ref: "nameInput"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                ]))
              }), 128))
            ])
          ], 64))
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        onClick: _ctx.sendTemplate
      }, {
        default: _withCtx(() => [
          _createTextVNode("Template senden")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 512))
}