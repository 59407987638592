import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import { doc, getDoc, getFirestore } from "firebase/firestore";

/**
 * Loads a conversation.
 * @param ids to load chats from
 */
export const getConversations = async (ids: string[]): Promise<IChat[]> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const chats: IChat[] = [];

  for (const id of ids) {
    const chat = await getDoc(
      doc(getFirestore(), "dialogs/" + chatbotId + "/chats/" + id)
    );
    chats.push({ ...chat.data(), id: chat.id } as IChat);
  }
  return chats;
};
