import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import ChatInputMentionUserSuggestionList from "@/components/inbox/ChatInputMentionUserSuggestionList.vue";
import { store } from "@/store";
import { AdminStore } from "@/store/constants";
import { IUserResult } from "@chatcaptain/types/organization";

export default {
  items: ({ query }) => {
    return (
      store.getters[AdminStore.Getters.GET_USERS] as IUserResult[]
    ).filter((item) => item.name.toLowerCase().startsWith(query.toLowerCase()));
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new VueRenderer(ChatInputMentionUserSuggestionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
