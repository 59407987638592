import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Assigns the caller as operator to the conversation.
 * @param id of the conversation.
 */
export const assignToConversation = async (id: string): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const result = await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      id +
      "/assign",
    "PUT",
    {}
  );
};
