import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c23a7b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details-link-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_button = _resolveComponent("md-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_input_field, {
      label: _ctx.label,
      modelValue: _ctx.link,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.link) = $event)),
      size: "small",
      error: false,
      onChange: _ctx.onChange
    }, null, 8, ["label", "modelValue", "onChange"]),
    _createVNode(_component_md_button, {
      variant: "icon",
      icon: "open_in_new",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openLink()))
    })
  ]))
}