
import { defineComponent } from "vue";
import MessageMixin from "@/components/inbox/messages/MessageMixin";
import { IChat } from "@chatcaptain/types/dist/chatbot";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MessageInfoModal from "@/components/inbox/messages/MessageInfoModal.vue";
import { IMdModal } from "@/components/md/MdDialog";

export default defineComponent({
  name: "Message",
  components: { MessageInfoModal, MdButton },
  mixins: [MessageMixin],
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    conversation(): IChat | null {
      return this.store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION];
    },
    image(): string {
      if (this.message.persona) return this.message.persona.image;
      if (this.message.sender === "user") {
        if (this.conversation && this.conversation.photoURL)
          return this.conversation.photoURL;
      }
      return "";
    },
    infoModal(): IMdModal {
      return this.$refs.infoModal as IMdModal;
    },
  },
});
