import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

export const sendTemplate = async (
  id: string,
  templateId: string,
  headerParameters: string[],
  bodyParameters: string[]
): Promise<void> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      id +
      "/sendTemplate",
    "POST",
    {},
    {
      template_id: templateId,
      parameters: {
        header: headerParameters,
        body: bodyParameters,
      },
    }
  );
};
