
import { defineComponent } from "vue";
import ChatCaptainGif from "@/assets/animations/chatcaptain.gif";
import MdButton from "@/components/md/MdButton/MdButton.vue";
import MdCard from "@/components/md/MdCard/MdCard.vue";
import { useStore } from "@/store";
import { InboxStore } from "@/store/constants";
import { IAnswer } from "@/types/inbox/answers";

export default defineComponent({
  name: "ChatInputAnswers",
  props: {
    query: String,
  },
  emits: ["select"],
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    answers(): IAnswer[] | null {
      return this.store.getters[InboxStore.Getters.GET_ANSWERS];
    },
    filteredAnswers(): IAnswer[] {
      return (this.answers || []).filter((answer) =>
        answer.name
          .toLowerCase()
          .startsWith((this.query || "").replace("/", "").toLowerCase())
      );
    },
  },
  methods: {
    onSelect(answer: IAnswer) {
      this.$emit("select", answer.message);
    },
  },
});
