import { View } from "@/classes/inbox/View";
import { orderBy, query, Query, where } from "firebase/firestore";

export class ViewOpen extends View {
  constructor() {
    super("Offene Konversationen");
  }

  async getQuery(): Promise<Query> {
    return query(
      this.defaultQuery,
      where("unread", "==", false),
      where("snoozed", "<=", new Date()),
      orderBy("snoozed", "desc")
    );
  }
}
