import { defineComponent, PropType } from "vue";
import { IChatMessage } from "@chatcaptain/types/dist/chatbot";
import isUrl from "is-url";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<IChatMessage>,
      required: true,
    },
  },
  computed: {
    parseUrlInMessage(): string {
      const msg = this.message.content.text || this.message.content.message;
      if (!msg) return msg;
      const splitted = msg.split(/(\s|\n)/);
      splitted.forEach((word, index) => {
        if (isUrl(word)) {
          splitted[
            index
          ] = `<a class="link" href ="${word}" target="_blank">${word}</a>`;
        }
      });
      return splitted.join("");
    },
  },
});
