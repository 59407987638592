import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59c78422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_avatar = _resolveComponent("md-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["item", { 'is-selected': index === _ctx.selectedIndex }]),
            key: index,
            onClick: ($event: any) => (_ctx.selectItem(index))
          }, [
            _createVNode(_component_md_avatar, {
              size: "small",
              variant: item.image ? 'image' : 'monogram',
              content: item.image && item.image,
              raw: (!item.image && item.name) || ''
            }, null, 8, ["variant", "content", "raw"]),
            _createElementVNode("p", null, _toDisplayString(item.name), 1)
          ], 10, _hoisted_2))
        }), 128))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, "Keine Benutzer gefunden"))
  ]))
}