import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f89a99c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "checks"
}
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round"
}
const _hoisted_3 = {
  key: 1,
  class: "material-icons-round"
}
const _hoisted_4 = {
  key: 2,
  class: "material-icons-round"
}
const _hoisted_5 = {
  key: 1,
  class: "material-icons-round failed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-indicator", { user: _ctx.message.sender !== 'bot' }])
  }, [
    _createElementVNode("em", null, _toDisplayString(_ctx.time), 1),
    (_ctx.message.sender === 'bot' && _ctx.message.state !== 'failed')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.messageStateLevel >= 1)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2, "check"))
            : _createCommentVNode("", true),
          (_ctx.messageStateLevel >= 2)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3, "check"))
            : _createCommentVNode("", true),
          (_ctx.messageStateLevel >= 3)
            ? (_openBlock(), _createElementBlock("i", _hoisted_4, "check"))
            : _createCommentVNode("", true)
        ]))
      : (_ctx.message.state === 'failed')
        ? (_openBlock(), _createElementBlock("i", _hoisted_5, "warning"))
        : _createCommentVNode("", true)
  ], 2))
}